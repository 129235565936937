import React from 'react';
import {Carousel} from "react-responsive-carousel";
import AboutDescriptionTop from "./aboutDescriptionTop";
import AboutDescriptionBottom from "./aboutDescriptionBottom";

const AboutComponent = (props) => {
  return (
    <>
      <AboutDescriptionTop/>
      <Carousel showArrows={true} onChange={() => {
      }} onClickItem={() => {
      }} onClickThumb={() => {
      }}>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/product/158790/image/xlarge-487c3c7092cb1b56a55c8d31c4ac3175.png?Expires=1592167291&Signature=fAf4ZkVvDCcMrroOuM9kSGAyhgWNqpe8Hia-QUowe2A6HDDtUP9Bm5iMKIPz8SBUBviZ2NzWCPA5xSBHMDmfboVUZj-JcPTek~stkbOO~V7TM7X4plBhTvtosqVhCK46LT5IrAq2MAyy32NiPyYeNyAljsiOhBS5zdCuMuQLO9-UQvRmy-PZSTacfRXPrYz7PsRKcTjZnudpUfJUo5~GXac9TKCf-KjZSN4SH-Oed~386imd6eH-IBiddoPJL2RhgGlYJdu9fvK54JrgMi0KL3Ke7grmsnz8X69JyY4hbxgZJqAxvoZ8Wv1ER4CXSLXi67eGqY5f9r~EyEi5uKmrBQ__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 1</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/101785/image/xlarge-258117fa8a6f4bc8a6046db3ffcf026f.png?Expires=1592167291&Signature=ga-31M-8JqnrPDz2zE2L-ze3RCTHL6Mux~fhZJl1bIaDTOxOFlmS2LJC6IkvSroA7RnXWcmnwYqDzbbUy5AeoV6GaNsu3BcvWhdvzK6~HI0d4vIDQKP4lp~80Zf9SGxfdjiWUTshrwqZpaUpKKd1rbWGNO7eba2o7DC-uD2mzKuMn-10KVpGV3P40IgUQAvnlSmk6cM0Xs8~uQCU3JASn4yxexeWuVqS9LLMNemafXW8ubIf-Bxlt0IwcE1q8r5mfwuitoIgSA0OCgWuC~TvCmJYCkZ1OAcTj9lKfEPz43ncOsRwMxzLeWOBHexPNlmdlyTBOQnJEuZoQEg6ufSaSg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 2</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/101781/image/xlarge-a3e485cd71dda8d06c37fba5e004616d.png?Expires=1592167291&Signature=NhzejnLDcJCs9fhqlr37Nw9AtfNhhXpn3Nb2c6HWlooT67Ch-bmMpmKgB5HINwMwhjddpaWISxP8ezfoV6lH4oymcDd~Bzokohpz-vQkAMiZs8qy~akvnpVPp7tYEPrNXrTAi5H2kQW3jPY12mNKXqCtk5n5sB5~ajU1BFPq3BYo1ElJBBKqRH~6I4IUiSZemo9TCbDmjkf~A77vq8H6EqcIVhY~T9N7FGmHn~F2lbJhlHfwa4KSsHFgUBSP~0ApQJxL1HgqYVZOcdXTsM7yqXWJgpfwxTQoI1iansRhve~ldQpJrracj9m9NznDYYXi0uiR8zeCJ5Nx~8uhoeG9vA__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 3</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/101784/image/xlarge-861ee25ca92ff05ce1a5b5ea5d350f0a.png?Expires=1592167291&Signature=BH8oBrTVU9M0pgfzM3~uL9IsIj5x~8GA~~dYoy01lkkiwwPYNI0T9QbKlrdWXn1g53cE3Uj4NYkPa~GrnF~RsqQOf5ZYz12EHuPNcGjYHra9VDU4c4vu5PCgoOlvG9AlbIshSCO6BBToR8B~lH5Ocl-FaIHBcIn0Y64w3IMJzud~t7hbdkhyvcG1sWt~0p4kDZ3DcW-xl6ZOqdlfHnCzmLaqHFjkJmbnYfAGP7sMkVMd4wwHBE65rP3aoqnTQysSL1A-WjrGuDGsoe2uzbNKZIl8KgHovjEbIKBCy3nj6XAGat5F5Bit44IVJE88GjwTXB6dbppsnbgmmTGdiLx4Mg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 4</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/101783/image/xlarge-fcbbc9a8c28537568831b56f140a2bdb.png?Expires=1592167291&Signature=h4wHhbfQbDj4HGbHu1uGlsjA5K2GiAg2n~GgSx3GaIDDyyrgfQ-Ua6fnPZ6k93H4EsMWlyvAJJfC4wIidl5pynFWpCxNYcSgz5M0Ltc33~CbJazPVMHW-5jOPUDcYvSeg8JgIk3v5Dgry2cXxQvyeXDnY5LjSyj7l-~wVTm7V0iZBRnsxPm71ztq0sFMZfGP6LgEFkaNfwu3w7zbMK2x5EsZcQ662nU9TgehWxbY-GxjNk0hGjf9~5vw3BDB4pjHsY0LtAf0OqMqn965Ao5KG7GdIecKOkPn4pTqIfQJ3hCwzdjqUOu1yJczT4u25T-Xk4~cmi5C0-FJ4vRpEdbzFQ__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 5</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/101782/image/xlarge-e4e497c5e1d02a5e3ad489c9c30c4257.png?Expires=1592167291&Signature=jFYmOi2WyN23D8Z85wMEPreRytMhgLMMgDCRMh4SQPw5E2PNEJjbVkakpX513VJApX7myRG9xpRL7Bm1NsridWG50K4Y~OPW~2yUrGNKKHcr7EMh9rAaIf4Ex4RcL2T4TzSRp0h7JsbmAvmDajDjeoBHNDsE93JnalT9eUIm7QXlsWMg4OPVuEUDY5p2LLusuRIwHAk25-wbmhC4FE1g1r0It1iQnkfOftzYTCROiguN0lwsmozZGR7yU3kEMtuaGW7PonA0YMPEgryDbS9Xln~z5CZ4isLbybvmHrU6MnCOEuCv9ZXdxA4a~qyCJaeXcCGRZmp3e3eju-sISS9-fg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100087/image/xlarge-68b520fda41d1763511fc93db223aec7.jpeg?Expires=1592167291&Signature=Y0I3NOMQKggJ7bLIqlAm0yAzEVUiWGKQdldR1GeERaOcHiKzgXJGTzNfB5waqH0uVf~dRLM-GFAzuZo87zjzwVYViZjd2bJaXS77Fcgxe6cQctR4TTnF1g99KK9~TIOo3Hl3vy-N3gaYzF94T2PBTFF57rcgNtWWE4hsXtQ058YZvdI9Gnngv8POzTanX8hJ78YXtTWmze8G2zSI5xNZ6TLvapYmFHfPtbyEYwMTlJw~nzcnYIhqD~pEx2wSP03mdjp5fRl~2hA4YtJhWAQHAh8uCMGCnH1Qw3Lx3-u4v0L-jSSjF4xtObVEFpZzhp4uW65PLwCfHOfosvLMMzfAFw__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>

        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100086/image/xlarge-62f1ce0b871821262a925fc3e16f6fe7.png?Expires=1592167291&Signature=PVfJvz-uKBOqtKDJFfkVc2WgZcY0q8nCTIeJVaGu0RREQuvbScZUVSoINHodyiciKYcELZrTR0rzNdeV4ENVlTQfAniRZkTHGViLoEMLM8SdLvNCcUAuXATPHjoYEJpViwl7Ji5poDkeJaoHLrqFB6U92mRX3LZb7fKc7s6X-g81pEKyk3C6sK8ov-~wD4ymEcqOV080LvT13-0E3hbRlQzyRAUrLfEJOxJ-BzTWlhqyfaMe54vilx~F-URsnMB0gRDz8K4CN0gcRNLG1IcWOokQDKG5RrA6wqPIB~f7qosIOFOXgsaqCh13YrH40lKAgvuAgFLY3k8GFXkLjzgBkw__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100083/image/xlarge-6fb726a2734001ca98f27bb8e9c93a6a.png?Expires=1592167291&Signature=OlQA0YrU71q-gLhgN8Yyocayl9bW8SjOFhLNUCVUgFr3Gbbb9nmhB5B2PrFLlEf~bPeQV1fFJXt53O1ct73GokuVYfVx6BfCHTHrh0DtSyzBpZ8MS1pOzqQstQ7IK2vtD3xS~zzlshzesbooq1I5scDJ3KLCcdOwOsSqv~7zNP0X4HhF1r2GTwE~~u73tAFHGWn6q5cWMZ5Q1Ork6SB-nP-MouJqWY3JM20Vw~DwtnE4WMuu~MP9mlwbNuZFf5J2p0EKDwAWQ8gjK08uDMTicyEYxmlPs5iC-Zae7KtWZpFPYUYsvVajGe1zx~QzEoVbp6cHJY6vBnUV7HP0MTR1vA__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        {/*<div>*/}
        {/*  <img*/}
        {/*     alt='empty' height={'auto'} width={'400'} style={{objectFit:'cover'}} src="/vertical.png"/>*/}
        {/*  /!*<p className="legend">Legend 6</p>*!/*/}
        {/*</div>*/}

        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100081/image/xlarge-da360f05d56131b575c19e2f214f570c.png?Expires=1592167291&Signature=U17WHez8XzK8zGLL17B8sbNLiAStKnt4vsQSPyOWWVSRI8gxFFlQm8oeT0YHlsfadxOSBp1nUlXSRaUTuIfr9v5wgDqJvWVsoqAmi3ZNP2w72I2zKL3Aj-U8gcfrV7kUQkkMQiY905NCIc0Epx0JuhQzl42sWsMTSAONgMpIGvixybg2Ey-AKUq6vMNqehalCulYz8ABqbqkE4NmyojFsuNpkLkXDVrAb2mFDqQmZWazFRNoR7q88pBzqmoPE3XWOiisBSuHrvzKTtgseMfarbwiUM3i8ZaSwn1VxPS9WVkOAR0~rW2BR-GAjSAlgn4fghn9tlfC0YGkYXWA-rjCTg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100080/image/xlarge-ad4dc3ded820f61299eed7f219e16e80.png?Expires=1592167291&Signature=iR2quAQcA014xYws2Z1pPUx9hmrab~WVVcgazP1XVTThYUossIUyYJ68khTICRNH8XK4o~9BzqArA-f91l46h7Pe-QJBkg92DJwtrkG4CWY4ssa9-M1Q1oQCSPWFD~o0N9OcE2XX-sAMDzyy5kO4F7sdVvR1JoSphqqognDkls8CZYY2afEMj1bepQtTN2akprrPeWN~zoO~ovQ1MEu~sxsGADbWEvi4IrI4lSkUcHRDFcKXnAa~rhYIA3K7k0dyF9JK~jYKdUI2JwyDeUa7uQ69pvEL8IU6b0XyN5n8FeBZzQgGFB45PC8lQs5j-KvcF0BDUJ~dCa9~1HAGwiol-Q__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>


        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100079/image/xlarge-23e0f65cc08fdf1d9846d3c43ccd1302.png?Expires=1592167291&Signature=K24JuxmlphiWzpaY2RIpniykFRprUQ5FhvZ-Fjic4uxJtxZIl3i1TDibqeIWluOcX42AXI6ltWNSLS0CPZbn40W04QjSLehTOh1xqu~jr-UE2FV-yXZZ1z8C3Y0-h7BRe0Hxkmju6cor3As1c1DUpKxIVKjgr9~KtdJJvyaKPYto~rba57LrRmfa-U70uskvTII4NbASK00BWek4s~hQ-yx6jvoey8ew4ke0ZpYrBBNsVPAbuNGduKDS2ab5EaN-vyxZhM9wRlbYNvXrHbspfEjNo3FCIUxs7shT8liY-5RSYcbUa6ydtytBohnIn9UkdEatg7ALuurz-2~nN0v~Cw__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>

        <div>
          <img
            alt='empty'
            src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100078/image/xlarge-9f94794708b0656ccd8c32011d1e1d43.png?Expires=1592167291&Signature=fgN5F-vsia9Un1LgL7-ubG3vCMBqlnB416aABMPUJ3-3ct2VoFOmeUuIXZBmK7zbbyJvFxt59Qq~kwNdCYXLysMmTJ2CT3pWh6MSSC5T~093eyTinxEZLIMnfzMiTKS5fR1zghBlQyTCrY7WUtgm7EgVuqwQUcDnlGM6fszqV3ft7Xgjjc-Nsf1TSPIc71KOfPmJCcch~2yDo3iEo1Puz1gCkMAQM3JNK9no69kGzXfhd68Ku4N6lBedDfmFEeHJ8mooiGwlPplAraKS5GlvEB7pnOBDIFF1tKO4sLG9PyrNy4krFaC9Y7mCwIDxKaWXYBRhvyk9OQyLEp9vfhqB7A__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>

        {/*<div>*/}
        {/*  <img*/}
        {/*    alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/100077/image/xlarge-36b66e802575c4c03cb3f7a2789271c4.png?Expires=1592167291&Signature=dU94nsFuz970EfrMSpPI0ZOtfv7LlA2V3YiXImhHzikQ~anFjzvW3Uo8bHYlZTM5p7jWsVLNUwVKXPm33RhIYNURSfygqwNLxKyx~YCQWLOhHqiw3VebVwhWbsB2~QoqSQGXDTHYloqjX4NGVV54FKA3QjR9rMVKVkK51SM9J0ovdAHxgeSJSeYYEL3eiztFwpH8-kD73eNpevz5U-MvouudzHu73kCpJ24E-OTlakegrUUSrAQLvJ0rzKnu0xFbBJagnhZ~N5IHHt3dcIvIrphN-WjL3Dk6VYwQ1tPeaVP~RdeG9EhT~yABBCk79vCGq-xCRUQ190jxjWEKtWiRBw__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>*/}
        {/*  /!*<p className="legend">Legend 6</p>*!/*/}
        {/*</div>*/}

      </Carousel>
      <AboutDescriptionBottom/>
    </>
  );
};

export default AboutComponent;