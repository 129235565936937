import React from 'react';
import WarningOutlined from "@ant-design/icons/lib/icons/WarningOutlined";
import {ExternalLink} from 'react-external-link';

const ForWindowsDocumentationComponent = (props) => {
  return (
    <>

      <p className={'ortho-about-p'} style={{backgroundColor: '#ffaf2e'}}>
        <WarningOutlined/> NOTE: For the windows installation of the DICOMtoMESH add on for Blender, you will need the
        0.8.0 version of the dicomtomesh command line tool developed by, Adrian Schneider
        AOT -AG which you will have to build and compile for Windows 10.
      </p>

      <div className={'ortho-about-p'} style={{fontSize: '110%', marginBottom: 18}}>
        DICOMtoMesh add on for Blender. Windows 10 installation instructions:<br/>
        It is CRUCIAL to follow the correct sequence as indicated below:
      </div>

      <div style={{backgroundColor: "black", fontSize: '110%', padding: 20, color: "white", borderRadius: 5}}>
        <ol>
          <li>Install Cmake from <ExternalLink href="https://cmake.org"/></li>
          <li>Install Git for windows (GitHub) <ExternalLink href="https://git-scm.com/download/win"/>
            <br/>Create an account on GitHub and remember username and password.
            <br/>
            Download the 0.8.0 version of the dicomtomesh command tool from Github. The master version of the program
            does not compile in windows, only in Linux and MacOs. So you need the 0.8.0 version:<br/>
            <b>Git https://github.com/AOT-AG/DicomToMesh/archive/0.8.0.zip</b><br/>
            Right click on the DicomtoMesh folder and select "GitBush here"

          </li>
          <li>Download and install latest SDK for windows</li>
          <li>Download and install Visual Studio with C++ support (15 or higher)</li>
          <li>Download and install Qt (QT5.9.4) <ExternalLink href="https://www.qt.io/"/> – open source version – you
            need to
            create an account to do this.<br/>
            There are videos on youtube on how to do this:<br/>
            <ExternalLink href={" https://youtu.be/u5-Df1YlxCI"}/><br/>
            The process for Qt installation is outlined below:<br/>
            Edit the system environment to include the Qt path. To do so, go to search toolbar of windows 10 and type
            "edit the system environment"

            Under windows folder there is the installation folder Qt. Add the folder msvc2017_64 to the system
            variables, then scroll down find the path and click edit. There you will edit the path and add new variables
            %QTDIR%\bin and %QTDIR%\lib directories to your path.

          </li>

        </ol>

        <h2 style={{color: 'white'}}>Download and install VTK (8.2.0)</h2>
        From <ExternalLink href={"https://vtk.org"}/> – download the zip file and extract it.
        See youtube video <ExternalLink href={"https://youtu.be/u5-Df1YlxCI"}/>  <br/>
        Open the VTK folder and create a folder named "build" in the same folder that contains the VTK subfolders.
        <br/><br/>
        Use cmake GUI to compile VTK.<br/><br/>
        Open cmake and under “where is the source ..”add the VTK-8.2.0/VTK8.2.0 folder that contains the subfolders.
        Under “where to build… “ add the build directory within the VTK-version folder that you created before.<b/><br/>
        Click generate and select as generator VISUAL STUDIO 16 2019 and under optional platform select x64. Then add Qt
        to the VTK build by doing the following.<br/><br/>
        After the generate process is finished in Visual Studio select the VTK-groupQT and click configure again.<br/><br/>
        Then click Generate and the click Open Project<br/><br/>
        Select ALL BUILD within visual studio and right click, then select build, this will take a while. Then open the
        visual studio with administrative privileges, reopen the vtk project and then select INSTALL right click and
        select build again<br/><br/>
        Then you need to add VTK to the path. Repeat the same process as Qt (see above)
        <br/><br/>
        VTKDIR and then point the path to the directory of the VTK installation – usually under Programs (x64) for
        Windows 10.<br/><br/>
        Then edit the path click new and add the path %VTK_DIR%\bin
        <br/><br/>
        Then you need to build the vtkDICOM library<br/><br/>
        Using the Git GUI application within Windows, download the VtkDICOM repository from Github in a folder of your
        preference<br/><br/>
        Git https://github.com/dgobbi/vtk-dicom.git
        <br/><br/>

        Make a build directory within the vtkDICOM library<br/><br/>
        Using the cmake GUI configure and generate as you did for the QT and VTK libraries
        <br/><br/>
        Open the solution DICOM in Visual Studio 2019 (run it as administrator. Run ALL BUILD and INSTALL as
        before)<br/><br/>
        Download the ReadDICOMSeries, NOT the ReadDICOM!! from the VTK website
        <br/><br/>
        Open the command prompt in Windows and extract the ReadDICOMSeries.tar file as below
        <br/><br/>
        tar -zxvf ReadDICOMSeries.tar -C “destination path”, for example “C:\ReadDICOMSeries”
        <br/><br/>
        Using cmake GUI configure and generate for the ReadDICOMSeries as you did before. You do not need to create a
        build folder, it already exists.<br/><br/>
        Open Visual Studio 15 or higher as admin and Build ReadDICOMSeries by running ALLbuild and build it – right
        click- on ALL build to do that,<br/><br/>
        <b>Now you are ready for the final step which is to compile the DicomtoMesh command line tool for
          Windows</b><br/>

        Within the Dicomtomesh folder – version 0.8.0. Create a “build” directory within the DicomtoMesh folder that
        contains the subfolders and use cmake GUI and Visual Studio to build the DicomtoMesh application as you did for
        the vtk above.<br/><br/>

        When using cmake to build DicomtoMesh check “advanced” and select the VtkDICOM options as well as “Build GUI”.<br/><br/>
        If everything goes well the executable files will be under under build/dicom2mesh/Debug for the dicom2mesh.exe file and build/gui/Debug for the dicom2meshgui.<br/>
        <br/>
        Next you can download the Dicom2Mesh.py script and install it under Preferences of the Blender Software.<br/><br/>

      </div>

    </>

  );
};

export default ForWindowsDocumentationComponent;