import React from 'react';
import {Card} from "antd";
import {SyncOutlined} from "@ant-design/icons";
import StripeCheckout from "react-stripe-checkout";
import useDownload from "../useDownload";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";

const DownloadComponent = (props) => {
  const {
    handleCharge2,
    state
  } = useDownload();

  const stripeConfig = {
    currency: 'USD',
    publishableApiKey: 'pk_live_51Gu3tVGmUZc8y4OFNL5Upzw2OAFRAqZJtGabOmDbI5PltNZFU8ojhieXOabBzMJeb4guMpEQ67pKlalizEHLw6pr008D8A8Eo0'
  };

  return (
    <div style={{maxWidth: 600, margin: "auto"}}>
      <Card
        style={{marginTop: 0, height:300}}
        type="inner"
        title="Dicom2Mesh"
        extra={
          <>
            <div
              style={{cursor: "pointer"}}
              onClick={() => {
                !state.isPaid1 &&
                document.querySelector(
                  "#rc-tabs-2-panel-5 > div > div > div:nth-child(2) > button").click();
              }}>
              <span className={'download'} style={{color:'white'}}> $100 </span>
              {
                state.loading1 ? <SyncOutlined spin style={{fontSize: 26,color:"white"}}/> :
                  <DownloadOutlined  style={{fontSize:20,color:"#2dff96"}} className={'download'} />
              }
            </div>

          </>
        }
      >
      </Card>

      <div style={{display: "none"}}>
        <StripeCheckout
          token={handleCharge2}
          currency={stripeConfig.currency}
          stripeKey={stripeConfig.publishableApiKey}
          name={'Download Dicom2Mesh'}
          amount={100 * 100}
          locale={'auto'}
          allowRememberMe={false}
        />
      </div>

      <div style={{textAlign: 'center', margin: 10}}>
        <div style={state.successPayment2 ?
          {visibility: "visible"} :
          {visibility: "hidden"}}
        >
          <span style={{color: 'blue'}}>
            Please rename the file Dicom2Mesh.txt to Dicom2Mesh.py
           </span>
        </div>
      </div>
      <a id={'dicom2mesh'} href={'/Dicom2Mesh.txt'} style={{display: 'none'}} download>Download dicom2mesh</a>
    </div>

  );
};

export default DownloadComponent;
