import React from 'react';
import {notification} from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CopyOutlined from "@ant-design/icons/lib/icons/CopyOutlined";
import WarningOutlined from "@ant-design/icons/lib/icons/WarningOutlined";

const DocumentationComponent = (props) => {


  return (
    <>
      <p className={'ortho-about-p'} style={{backgroundColor:'#ffaf2e'}}>
        <WarningOutlined />  Note: please refer to the installation instructions for details on how to implement
        the ReadDICOMSeries function.
      </p>

      <div>
        <iframe title={'title'} style={{width: '100%', height: 639}} src="https://www.youtube.com/embed/Uzf_YYqsci0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen> </iframe>
      </div>

      <p className={'ortho-about-p'} style={{marginTop: 20}}>
        DICOM2Mesh is an addon for Blender 2.8 that allows to import DICOM files to Blender in the form of a Mesh Model.
        DicomToMesh is a small in-house product of AOT AG
        <a style={{color: 'yellow'}} href={'http://www.aot.swiss'}> (http://www.aot.swiss). </a>
        Since it is based on several open-source examples, they report that they decided to make the code public as
        well.
        The DicomToMesh runs in the background when the Dicom2Mesh python script is running in Blender. DicomToMesh is a
        command line tool used to transform a DICOM volume into a 3d surface mesh (obj, stl or ply). Several mesh
        processing routines can be enabled, such as mesh reduction, smoothing or cleaning. Works on Linux, OSX and
        Windows.
        The Dicom2mesh command line open source program information and installation instructions can be found at
        <a style={{color: 'yellow'}}
           href={'https://github.com/AOT-AG/DicomToMesh'}> https://github.com/AOT-AG/DicomToMesh</a>
      </p>

      <div style={{backgroundColor: "black", fontSize: '110%', padding: 20, color: "white"}}>
        Briefly the following steps work for installation on MacOS and possibly for Linux and Windows <br/>
        Open a Terminal, and follow the steps below:<br/>
        • Check if brew is installed in your computer<br/>
        <CopyToClipboard text={'brew -v'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> brew -v <CopyOutlined/></button>
        </CopyToClipboard><br/>
        • if not then install it<br/>

        <CopyToClipboard
          text={'/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/master/install.sh)"\n'}
          onCopy={() => {
            notification.success({
              placement: 'bottomLeft', duration: 2,
              message: 'copied to clipboard !'
            });
          }}>
          <button className={'copy'}>>> /bin/bash -c "$(curl -fsSL
            https://raw.githubusercontent.com/Homebrew/install/master/install.sh)"
            <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>

        • If brew is installed make sure brew is updated:<br/>

        <CopyToClipboard text={'brew upgrade'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> brew upgrade <CopyOutlined/></button>
        </CopyToClipboard>

        <br/>
        • Install vtk<br/>

        <CopyToClipboard text={'brew install vtk'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> brew install vtk <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>
        • Create a folder where the dicom2mesh will be installed – example Desktop/SourceDicom2Mesh<br/>
        • From that folder from the terminal run the following commands:<br/>

        <CopyToClipboard text={'git clone https://github.com/AOT-AG/DicomToMesh.git'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> git clone https://github.com/AOT-AG/DicomToMesh.git <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>

        <CopyToClipboard text={'cd DicomToMesh'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> cd DicomToMesh <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>

        <CopyToClipboard text={'mkdir build'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> mkdir build <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>

        <CopyToClipboard text={'cd build'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> cd build <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>

        <CopyToClipboard text={'ccmake .. '}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> ccmake .. <CopyOutlined/></button>
        </CopyToClipboard><span> #opens ccmake window (dont forget the two dots after the word!)</span>
        <br/>

        <br/>
        • Within the ccmake window, pass the path to your vtk installation or vtk build directory. It should looks like
        that:
        <br/>
        <img style={{maxWidth: 400}} src={'/instructions.png'} alt={'instructions'}/><br/>

        • Press c and then g. Now you are back in terminal and ready to build.

        <br/>

        <CopyToClipboard text={'make'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> make <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>

        <em>The executable files will be under Build/dicom2mesh for the ./dicom2mesh command line tool and under
          Build/gui/ for the./dicom2meshUI graphics interface.</em>

        <br/>

        <span>
          Do not forget to “make”. Run the command line tool and make sure it is installed correctly within the Build folder (>>cd Build/dicom2mesh/), type the following command to run it
        </span>

        <br/>

        <CopyToClipboard text={'./dicom2mesh  -h'}
                         onCopy={() => {
                           notification.success({
                             placement: 'bottomLeft', duration: 2,
                             message: 'copied to clipboard !'
                           });
                         }}>
          <button className={'copy'}>>> ./dicom2mesh -h <CopyOutlined/></button>
        </CopyToClipboard>
        <br/>
        <p>Make sure the dicom2mesh command tool is installed correctly before proceeding further.</p>

        <p>Download the Dicom2Mesh.py from the https://www.orthomesh3d.com website and install it using the user preferences
          installation method. Expand the arrow and provide the path of the installed dicom2mesh and dicom2meshui files.
          If this path is not correct the add on will not work.
        </p>
      </div>


      <a target="_blank" rel="noopener noreferrer"
         href="https://s3.amazonaws.com/markets-rails/uploads%2F1589399877435-Screen+Shot+2020-05-13+at+3.54.52+PM.png">
        <img style={{maxWidth: '100%'}}
             src="https://s3.amazonaws.com/markets-rails/uploads%2F1589399877435-Screen+Shot+2020-05-13+at+3.54.52+PM.png"
             alt=""/>
      </a>
      <a target="_blank" rel="noopener noreferrer"
         href="https://s3.amazonaws.com/markets-rails/uploads%2F1589399918539-Screen+Shot+2020-05-13+at+3.55.15+PM.png">
        <img style={{maxWidth: '100%'}}
             src="https://s3.amazonaws.com/markets-rails/uploads%2F1589399918539-Screen+Shot+2020-05-13+at+3.55.15+PM.png"
             alt=""/>
      </a>
      <a target="_blank" rel="noopener noreferrer"
         href="https://s3.amazonaws.com/markets-rails/uploads%2F1589399939383-Screen+Shot+2020-05-13+at+3.55.39+PM.png">
        <img style={{maxWidth: '100%'}}
             src="https://s3.amazonaws.com/markets-rails/uploads%2F1589399939383-Screen+Shot+2020-05-13+at+3.55.39+PM.png"
             alt=""/>
      </a>
      <a target="_blank" rel="noopener noreferrer"
         href="https://s3.amazonaws.com/markets-rails/uploads%2F1589400025030-Screen+Shot+2020-05-13+at+3.56.02+PM.png">
        <img style={{maxWidth: '100%'}}
             src="https://s3.amazonaws.com/markets-rails/uploads%2F1589400025030-Screen+Shot+2020-05-13+at+3.56.02+PM.png"
             alt=""/>
      </a>
      <a target="_blank" rel="noopener noreferrer"
         href="https://s3.amazonaws.com/markets-rails/uploads%2F1589400025030-Screen+Shot+2020-05-13+at+3.56.02+PM.png">
        <img style={{maxWidth: '100%'}}
             src="https://s3.amazonaws.com/markets-rails/uploads%2F1589400055102-Screen+Shot+2020-05-13+at+3.56.32+PM.png"
             alt=""/>
      </a>


    </>

  );
};

export default DocumentationComponent;
