import React from 'react';

const FooterComponent = (props) => {
  return (
    <footer id={'footer'}>
      <div style={{marginTop:2}}>
        <em> Anastasios Papadonikolakis, MD, PhD <p style={{marginTop: -6}}><small><small>papadonik@hotmail.com</small></small></p>
        </em>
      </div>
    </footer>
  );
};

export default FooterComponent;