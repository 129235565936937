import React from 'react';
import {Tabs} from "antd";
import AboutComponent from "./about.component";
import DocumentationComponent from "./documentation.component";
import DownloadComponent from "./download.component";
import {Animated} from "react-animated-css";
import ForWindowsDocumentationComponent from "./forWindowsDocumentation.component";


const {TabPane} = Tabs;
const Dicom2MeshComponent = (props) => {
  return (
    <Tabs tabPosition={'top'} style={{}}>
      <TabPane tab="About" key="1">
        <AboutComponent/>
      </TabPane>
      <TabPane tab="FAQ" key="2">
        <p className={'ortho-about-p'}> Where can I find more information?
          <br/>
          <a href={'https://www.udemy.com/course/orthomesh3d/'}>https://www.udemy.com/course/orthomesh3d/</a>
        </p>
      </TabPane>
      <TabPane tab="Documentation" key="3">
        <DocumentationComponent/>
      </TabPane>

      <TabPane tab="Installation for Windows 10" key="4">
        <ForWindowsDocumentationComponent/>
      </TabPane>

      <TabPane tab="Download" key="5">
        <Animated animationIn="fadeInLeft" animationInDuration={500}  animationOut="fadeOut" isVisible={true}>
        <DownloadComponent/>
        </Animated>
      </TabPane>
    </Tabs>
  );
};

export default Dicom2MeshComponent;