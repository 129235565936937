import React from 'react';

const AboutDescriptionTop = (props) => {
  return (
    <><div className={'ortho-about-p'} style={{fontSize:'110%'}}>
      Dicom2Mesh is a Python script Addon for Blender that allows the user to select a DICOM volume from within the
      Blender environment, generate a 3D surface mesh from those DICOM files and import the surface model in the STL
      format in Blender. In addition, it allows to browse the DICOM volumes, preview the DICOM slices prior to
      generation of the 3D model. In conjunction with the OrthoMesh3D it can be used to simplify the process of
      working with DICOM volumes and generating 3D surface mesh models of bones for virtual surgical planning. The
      Add on utilizes two open source free command line tools that run in the background during the process of
      importing the DICOM series to Blender as a 3D model.
      <ul className={'ortho-about-p'}>
        <li>the DicomToMesh command tool which is written in C++ and utilizes the VTK library for conversion of CT
          dicom volume to a 3D mesh model
        </li>
        <li>the ReadDICOMSeries command tool which is written in C++ and also utilizes the VTK library browsing and
          viewing DICOM images
        </li>
      </ul>
    </div>
  </>

  );
};

export default AboutDescriptionTop;