import React from 'react';

const AboutDescriptionBottom = (props) => {
  return (
    <p className={'ortho-about-p'}>
      The Dicomtomesh tool developed by Adrian Schneider, AOT AG (Advanced Osteotomy Tools) which requires prior
      successful installation of this open source software. The Dicomtomesh developed by Adrian Schneider is based
      on the VTK library. Several mesh processing routines can be enabled, such as mesh reduction, smoothing or
      cleaning, preview of the 3D mesh prior to importing to Blender, or use of the Advanced Osteotomy Tools
      interface to import the DICOM volume . The addon works on Linux, OSX and Windows. THIS IS A NON FDA approved
      software and it is NOT intended for clinical use.
    </p>
  );
};

export default AboutDescriptionBottom;