import React from "react";
import 'antd/dist/antd.css';
import NavComponent from "./components/nav/nav.component";
import FooterComponent from "./components/footer/footer.component";

const App = () => {


  return (
    <>
      <div style={{marginBottom: 60}}>
        <h1 id={'header'}>
          <b>
            <img
              alt={'logo'}
              id={'logo-3d'}
              src="https://img.icons8.com/nolan/64/3d-scale.png"
              style={{marginRight: 20}}
            />
            OrthoMesh3D - Dicom2Mesh
          </b>
        </h1>
        <div style={{maxWidth: 1300, margin: "auto"}}>
          <NavComponent/>

        </div>
      </div>
      <FooterComponent/>
    </>
  );
}

export default App;