import React from 'react';
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const AboutComponent = (props) => {


  return (
    <div>
      <p className={'ortho-about-p'}>OrthoMesh3D is an add on toolbar written in Python for blender. It helps the user
        with the process of inserting
        bone models (obj, stl files) into Blender and planning orthopaedic surgery. Osteotomies ie Bone cuts via Boolean
        operations, Drawing of bone implants from SVG files is also provided.
      </p>

      <Carousel showArrows={true} onChange={() => {
      }} onClickItem={() => {
      }} onClickThumb={() => {
      }}>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/product/157607/image/xlarge-7e212f7fb3b5d23946ae7347bd14aebf.png?Expires=1592114523&Signature=LpwdIfr~RZM8HPE4HUl6iLvHMvYBsHD4Oeu4Rr8V4fxcA-MxXzHTU1vRT6YTsr7neNsX1L3671DepBrdPAn-yMHW7DgCJJt17OsjZaG5nrmUP50WSP1UANBCzfOp79B80XTH9AI2eo2EAX91pn-c3io7iCbpjk7~TI0szOraGFFVF9gihxbE-xMZwOJdaa3D2tZAjU518dLgIdyak2Ufi9pJnvqLsO48n2Or7Ptjoz4RTPpAOU8lqENWy9COALMDEFpOyhFIXLM-NmYLBTsV1k~wzs3taJR5cOFiI6NIc5J8fveJ7kEJf17bezqWxsbUqyyDibDuto9WRsYOgqE9CA__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 1</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/86643/image/xlarge-8d58e4c41e5ce6fc1901e1540612636a.png?Expires=1592114523&Signature=goV8M4~T8aDevM6DJkuCaZZjoADjzeULXYAHuWP0cdubeoZIlbEGKuurfPTag2kg0clA6FpN3EQWhTERHZFNSNV-yRnEE2NTH0jM9mGKKqy63XIrehsHVM8Rfv-Pl13OC-8onP53ehVoOYIm307vi7I2ptbEG5DuGGHN5ISmei1sqS1BfiP5rTPgcQV~Z~aeVtIu-NDHlYJPNTGo54OTUCaNS1hJ3H0nc2NRJSHRfcLWs9LlJsWzkB26lY63DkH8Dzj49xxUfxJOxVcQMzqDzlFA~fXMejRG6yDqQfVZpMsueDZSENqYBsijSFFT6KuOXF1NnVjcFE2DrIYI13mr-Q__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 2</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/86642/image/xlarge-0361611df38eadc58544c1b4385c3360.png?Expires=1592114523&Signature=JS4thoWYyGl6NAkU8iaDlCAEJ4E-PNUShl2WDKld7~grB68iicnRtNNdLpzJ-AbEb3M~gTy5RT6WQfPyiZRueWlxtWQsZp1njOZi5fQksMt-pWO8Y8MaKveU6vliyDTffb6F8Q~GQC-4wTOXXLEGM9r8NUfAsEhqjktxEjvmnzzzWKDEd6-dA9DqjEVv7nld10tcOf~2yU~B8v9Oeo6HK8Ele6LIMjgyRhXuUfF~sr8oDzphmWg10lU8nwYg2~UwHw41r-Q-Sdsa81eHF8O~RprysDsVAZ5ukXrdq3uLwuomUk8NIzbmLCqaCSa2OLWGjxDdWAaQD2YNKtP~QKWEfg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 3</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/86639/image/xlarge-f70e9c9d3012aed5fcdf47a3840e64f9.png?Expires=1592114523&Signature=SjoOIuUJ0~MalNNu~fgp-3EZ1ik1lgEE0pemIt5N8sxt~vgqxFu33OL1VIH-4vDlUeYvzarxvJAJ7hW8tCwGiSZffLv5IOooczLsRWFYnUWSDPiK9h59n0Fm1Uoisx8M2W7X0sc31yf1s2A4dlF1icfRMiwdvbbq0q9udGGjyoK170iaOeqjONBr4YAbj8NlSq2NC3tkwQvtenCyw0D09~sFXVl57ihbcxxdRyhtoRd87RlsI8gUKfz1aWLM4FlMzIY0sSzxV43QaSI7KihogqtYHsYB~i31FxDU0lU~HcSeyvZ7TyiUc3REDLQn6qflI0zJ18duGlT5lJescUbuAQ__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 4</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/86641/image/xlarge-202a4570ed50f1f80cbfcc69bb327458.png?Expires=1592114523&Signature=NhiyEpCgfHbwSn8VMqCnM68vdYfWijkU4L4ltOoWTXUbJUiyqrd15-ewr7n36k3QHhlLVlzce5CCFZTgiex9wRDLOYjVpP8cl-KTYLUf-QN5U9Otu7-F6XgeIGyCdkVie5Tkn7whg9FG~po7WsbljUyMFk9xyWqBZ4MD0l4xJIlZYIEcGffCADZPg0voRglcjTlcCojJLMliUUnIxA9HpIVc-eri1SnxWJgydQgEDoQod7MEbLYYBs4fLD1Oy1lCMNqthokif5ZtinyRmXLzr9MTOpa9~Ejzk52RmBRgJKH15GOsiXFTA7BGAFon6rPo5lKkPdgUlphx4gTB52ACfw__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 5</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/86640/image/xlarge-c327496e566b0fe74e0b4308e1705469.png?Expires=1592114523&Signature=Uzp9lCO3r4RKCVMli5iJSP8wWL5NaMLEk1vHpVOLvdmARsfbqs63a7w~rG~E9JqtRel0UKlhbmHepu4r8Hb~gjRfYmnj7Rl0WZUFIxQmY4rnD9Vhbr5A7fQLRIbMsISxmOjck8Pvg7irfaMmZlJob4OmF2UDvyGfcpnrRaXhVaQ59gd4Abj5iMmI3KsPpgcRNZ6ln5npJOAkvawj~FFsmXJB2a6pVtY8BRJZ7aEzSDrUjPIa4v-I1puU-dXyPmRD69qwsZCqq32yXX0Pg~ugs3yAQ~x2ky7-WNjRrnb~8e~MZDfWpltEit7ItzKXV-A4hWDpM16~axWdcJ6DfBuJUg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/86638/image/xlarge-1cab87d4325fcb077b00f4f850754730.png?Expires=1592114523&Signature=BqLU0IzQnEExNqFH1WYQiiUuJlnpWIgiod1qCjNmI0Q7aOrO9c-2r7QvCdhIJ3Fb8-9S8MJqvyCq9N3lDEECcwkNIQDGrlNoGzxTVJ3YzEZ7z8J2FkWPsYQCkt5v0zNq1YXBifPlBYBUpOTZ52hrDNQdw91WBfbziAMnVHrM6UqmOA-Lq1UweSNyO5VJhXGUIbODExNi-zAq3JL5xzCUEi~cQv39Cu-plWU18eLJQBEAkAPmfNfw2Ie-jKbgmfHVTa~snkAX0dTzb6rnO91F-J4bFeDw3hwUKe63y7ceXB8PMQNKYWRq7sy70FuqCOCLBCLC~~5qtvdbPZ44wJWk-Q__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>

        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/98326/image/xlarge-a50b762f74225f3b556f4ad9c1d05de1.png?Expires=1592115694&Signature=TbGAo~mpN46VAY7VeOAlgOXFtP4mtyCRP55xO0OM9lK7-p3d9zM37iaaVH1bg0gN5fnTmLCNFRonLmx~HQOgxFJdu9aFJzrFSlcE~dF1L6shYIr1ZspZIHrR2Dr18346yHi1cuhn~DbqBK65438OqR2uwxtt5lbUAQ-8pEyjsfWZ-ToqpMNM6jGdMV5Iapek4k3v2y~jAlDCIo1U-bl5Ltt3AoeMtF9PF6msvmeFQxRi2bsRzpN-L8oFltweTLykDnYZM2z7fm0EGSd3lZdzDpMYTWjfp3oxSZxqMHl6u5CIos8d0Rh32-ExRqfHWH~4dPyFJvw12tBmHJOlMXMXHg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/98325/image/xlarge-51a54f291c1203ccd8af6eb6cbf8dd22.png?Expires=1592115694&Signature=JgRYjWgP37cBlfWeKRKFQCkm8AK1oRjAdl18riPAkkXNNhuzhV8X6v6Si~wtvYX~xbtd8wb8TUII4~41NbnyPNvsJVo-~RgU0ZSwzwknO1CAr4oLihhr3MVExDukzPghUKg0KcOYsWVX8CDK0cNeV4vSI1NxNp0CdxvzXBP52etIvpEUvqmzGcbAgDRzAAhk1mTHBp3X~y4uZK4f31BEvgnuEKyS-3OGXvC6ijFJKtWO21tVFWMv7yQtXzFMjXW7tF~9Ml8LaTaWBGAIgj6KWeEsGPvheXqipEDdRvMwtdtHgzTbDyXGNFMVZg1-dkvxz6uKh4nXFC0~8l3Ddd9nug__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/98324/image/xlarge-cc0680499da184bb8bb9d8c7d68a162b.png?Expires=1592115694&Signature=DT5bETXM014v7u9uh0c2exFoEWVEG4acf7cODr4buFVBfC83ZNceZKcxiD8KQqzuaL1dmqf5wC0pCzDdB3Npr~3x~205oVMHKMPHXCaRXjjd2S4cwIYN6EE62DqION4ZZ~QdMCYEk4gimVP8IF~PbHYjKZdIdnbixmvmJulTqjAFVcnoHTJSZya4dCp9x7APFRZNox3KFgXD2Yt90Qf1QxPErULkhSpLwPNSUoZrTfdHkihqzH54osZnIB9L0s1sCB8XpzO0-v5-zNQW3pqY65briVwmQI2p9HmMSWFkxuwQKHRUxCZjPgE1OSL7WO3NWpw-IMs0N1CrnpXlAK02Qw__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/98323/image/xlarge-2b9ed540f95a2aaa0dbc26b5cd5d9290.png?Expires=1592115694&Signature=L1YzP9~BRP7RsnxGu2R-q1Jb4o47gTtk3qKumAbrFwbYzCG9FdDlN0GHoFubzc5HDiyCgpSFXzo6rChP9pDWDcudf0mGtH-UKpKcFipdWRiD-TYQoFOvCvXXHu-MlKfPQf6eL6Ju2aY98Vadyc5ORPbVYLDv1lcstqTYitDrp1lrUZfM3hpZIySeQELQGM3a~ExRAMAsB0YKINDlUp6Rn9XKyR7thgicrMkGuI2DADRwJWNCAmKcpqLdh1mrIqGfe27Zl0q-haWgRLDw7yK2wtXBVxfjT8t~mp9IL05S~W8vsQE6WFDsVYMKpC6gg3d~Ipy9YGtun4sB4pj45CZCAg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
        <div>
          <img
            alt='empty' src="https://d1231c29xbpffx.cloudfront.net/store/productimage/98322/image/xlarge-6f4859eb04f0ef116d8e34428cafb324.png?Expires=1592115694&Signature=TDWHhxFywd~NYoCFiLDyaTZVCzxTry-LIoHsdDPlxSuqD1rOo~rP7GlQFdA326~LKveFXQRmLHoaVajUcBsS8syPitbrjvFJPKPj5oTUlQEqThw5f1FmWwWGuI6lrYwQgLGyNHioISvKJtLBlC1X2pqbPU1PU8OX-sufCAa23mlssy0MXMr5HDmasF-i2SqmzYonUF8w56C7NIL3Qzlpvt0K4mVq9em4UeJqTOgdZ2NAmk3dYKplWFgdfuVbyy6LtDT7ucOdYIf1KymyizOjKz9I0ljy3AqvxMG8QwtFYkeuEf5xc7kW52jjLIzSkc8zdAR1bCYxyk5IkJvTyQWzpg__&Key-Pair-Id=APKAIN6COYBF3ZQW7OQQ"/>
          {/*<p className="legend">Legend 6</p>*/}
        </div>
      </Carousel>

    </div>
  );
};

export default AboutComponent;