import React from 'react';
import {Tabs} from "antd";
import AboutComponent from "./about.component";
import DownloadComponent from "./download.component";
import {Animated} from "react-animated-css";

const {TabPane} = Tabs;

const OrthoMeshComponent = (props) => {
  return (
    <Tabs tabPosition={'top'} style={{}}>
      <TabPane tab="About" key="1">
        <AboutComponent/>
      </TabPane>
      <TabPane tab="Documentation" key="2">
        <a href="https://www.udemy.com/course/orthomesh3d/"><p className={'docu-orthomesh'}>Please visit Udemy for a
          full course on 3D surgical planning using the
          OrthoMesh3D software. It is
          recommended to register for the course, but you may choose to use the product OrthoMesh3D without registering
          for the course.</p></a>
      </TabPane>
      <TabPane tab="Download" key="3">
        <Animated animationIn="fadeInLeft" animationInDuration={500}  animationOut="fadeOut" isVisible={true}>
          <DownloadComponent/>
        </Animated>
      </TabPane>
    </Tabs>
  );
};

export default OrthoMeshComponent;