import {useState} from 'react';
import axios from "axios";
import {message} from "antd";
const initialState = {
  loading1: false,
  loading2: false,
  successPayment1: false,
  successPayment2: false,
  isPaid1: false,
  isPaid2: false
}

const useDownload = (props) => {

  function Download(id) {
    document.getElementById(id).click();
  }

  const stripeConfig = {
    currency: 'USD',
    publishableApiKey: 'pk_test_51Gsi1DJotkTGrgdS8WcjUjPYkOVvt8qbfmOJd8znf7KXqKpxRmKPmBGBsS8IkImVODacNnDBZaa9U8qnJ7jbbdME00GiXpg9s6'
  };

  const [state, setState] = useState(initialState);

  const handleCharge1 = async (token) => {
    setState({...state, loading1: true})
    try {
      const result = await axios.post(
        '/charge',
        {
          token,
          charge: {
            currency: stripeConfig.currency,
            amount: 50 * 100,
            description: 'Orthomesh3D'
          },
        });

      if (result.status === 200) {
        message.success("Downloading ...");
        Download('orthomesh');
      }
      setState({
        ...state,
        loading1: false,
        successPayment1: true,
        isPaid1: true
      });

    } catch (e) {
      console.log(e)
      message.error('Something went wrong');
      setState({...state, loading1: false})
    }
  };

  const handleCharge2 = async (token) => {
    setState({...state, loading2: true})
    try {
      const result = await axios.post(
        '/charge',
        {
          token,
          charge: {
            currency: stripeConfig.currency,
            amount: 100 * 100,
            description: 'Dicom2Mesh'
          },
        });

      if (result.status === 200) {
        message.success("Downloading ...");
      }
      Download('dicom2mesh');
      setState({
        ...state,
        loading2: false,
        successPayment2: true,
        isPaid2: true
      });
    } catch (e) {
      console.log(e)
      message.error('Something went wrong');
      setState({...state, loading2: false})
    }
  };
  return {
    handleCharge1,
    handleCharge2,
    state
  };
};

export default useDownload;