import React from 'react';
import {Tabs} from "antd";
import OrthoMeshComponent from "../orthomesh/orthomesh.component";
import Dicom2MeshComponent from "../dicom2mesh/dicom2mesh.component";

const {TabPane} = Tabs;
const NavComponent = (props) => {


  return (
    <Tabs defaultActiveKey="1" type="card" style={{padding: `0 82px`}} onChange={(e) => console.log(e)}>
      <TabPane tab="OrthoMesh3D" key="1">
        <OrthoMeshComponent/>
      </TabPane>
      <TabPane tab="Dicom2Mesh" key="2">
        <Dicom2MeshComponent/>
      </TabPane>
    </Tabs>
  );
};

export default NavComponent;